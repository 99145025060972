
body.toolbar.network.has-national-chapter {
  //padding-top: 231px!important;
}

.ncmembers {
  h1 {
    flex-basis:100%;
    color: $color-base-black;
    margin-bottom: 0;
    margin-top: 30px;
    font-family: $font-family-muli;
    font-size: 2rem;
    font-weight: 200;
    //text-transform:uppercase;
    padding: 15px;
  }
}

.has-national-chapter {
  font-family: $font-family-muli;
  .view-front-country {
    &.view-display-id-page {
      header {
        display: none;
      }
    }
  }

  #country .network-full-info {
    &.country {
      display: none;
    }
  }

  .submenu {
    box-shadow: none;
  }

  #nc-content-wrapper {

    .container {
      max-width: 1200px !important;
    }
    .nc-header-wrapper {
      min-height:600px;
      height: calc(100vh - 231px);
      background-color: $nc-green;

      @media screen and (max-width: $screen-xs-max){
        height: auto;
        padding-top: 30px;
      }
      .row {
        height: 100%;

        .national-chapter-info {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          .nc-data {
            .nc-logo-img {
              width:200px;
              height:200px;
              background-repeat:no-repeat;
              background-position: left bottom;
              background-size: 100%;
              margin-bottom: 30px;
              //border: 1px solid white;
              //border-radius: 50%;
            }
          }

          .green {
            color: $color-base-white;
          }

          p {
            color: $color-base-white;
          }

          h2 {
            color: $color-base-white;
            margin-bottom: 30px;
            margin-top: 30px;
            font-family: $font-family-muli;
            font-size: 2.5rem;
            font-weight: 200;

            span {
              font-weight: 400;
            }
          }

          h1 {
            color: $color-base-white;
            margin-bottom: 0;
            margin-top: 15px;
            font-family: $font-family-muli;
            font-size: 3rem;
            font-weight: 400;
            text-transform: uppercase;
          }

          a {
            color: $color-base-white;
          }

          .item {
            display: none;
            &.memb {
              display: block;

              p {
                text-transform:uppercase;
                font-family: $font-family-muli;
                font-size: 1rem;
                font-weight: 200;
              }
            }
          }

          .description {
            p {
              font-size: 1rem;
              font-family: $font-family-muli;
              font-weight: 200;
              margin-bottom: 15px;
            }

            .d-block {
              display:block;

              &.maill {
                margin-top: 15px;
              }
            }
          }

        }

        .nc-leadbg {
          background-repeat:no-repeat;
          background-position: center;
          background-size: cover;
          height: 100%;
        }
      }

    }


  }

  .national-chapter-contact {
    .nc-contact-img {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    &.flex-container {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        line-height:30px;

        h1 {
          flex-basis:100%;
          color: $color-base-black;
          margin-bottom: 0;
          margin-top: 30px;
          font-family: $font-family-muli;
          font-size: 2.5rem;
          font-weight: 200;
          text-transform:uppercase;
          text-transform: uppercase;
          padding: 15px;
        }

        .flex-item {
          margin: 15px;
          color: $color-base-white;
          font-size: 1.5em;
          text-align: left;
          flex: 0 0 auto;
          height:auto;
          width:calc(25% - 30px);

          @media (max-width: 1024px) {
            margin: 7px;
            width:calc(25% - 14px);
          }

          @media (max-width: 768px) {
            margin: 10px;
            width:calc(50% - 20px);
          }

          @media (max-width: 400px) {
            margin: 10px;
            width:calc(100% - 20px);
          }

          display: flex;
          align-items: flex-end;

          &:before {
            content:'';
            float:left;
            padding-top:100%;
        }
      }

      &.member-placeholder {
        .flex-item {
          width:calc(16.6666% - 30px);
          &:before {
            padding-top:110%;
          }
        }
        .contact-data {
          line-height: 22px;
          font-size: 14px;
        }
      }

    }

    .contact-data {
      font-family: $font-family-muli;
      font-weight: 300;
      font-size:16px;

      p {
        font-family: $font-family-muli;
        font-weight: 200;
        font-size:13px;
        line-height: 13px;
      }
    }



  }



  .content-news-wrapper {
    margin-top: 0;

    #block-views-front-news-block-1 {
      .block-title {
        display: none;
      }
    }
  }

  .news-wrapper-title {
    h1 {
      flex-basis:100%;
      color: $color-base-black;
      margin-bottom: 0;
      margin-top: 30px;
      font-family: $font-family-muli;
      font-size: 2.5rem;
      font-weight: 200;
      text-transform:uppercase;
      text-transform: uppercase;
      padding: 15px 0;
    }
  }

  .events-wrapper {
    h1 {
      flex-basis:100%;
      color: $color-base-black;
      margin-bottom: 30px;
      margin-top: 30px;
      font-family: $font-family-muli;
      font-size: 2.5rem;
      font-weight: 200;
      text-transform:uppercase;
      text-transform: uppercase;
      padding: 15px 0;
    }

    .view-content {
      .views-row {
        padding: 7px 15px;

        &.views-row-odd {
          background-color: $color-grey-news-bg;
        }
      }
    }

    .date {
      text-transform: none;
      font-family: $font-family-muli;
      font-size: .7rem;

      .date-display-single {

      }
    }

    .title {
      font-family: $font-family-muli;
      font-size: 1rem;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .pager {
      text-align: right;
      li {
        font-family: $font-family-muli;
        position: relative;
        &:before {
          content: 'i';
          font-family: owsd;
          font-size: .8em;
          color: #005d25;
          position: absolute;
          left: -3px;
          top: 3px;
        }

        a {
          border: none;
          font-family: $font-family-muli;
          border-radius: 0;
          padding-left: 20px;
        }
      }
    }

    .resources {

      .view-content {
        .views-row {
          padding: 15px;

          .file-wrapper {
            position: relative;

            .file {
              .file-icon {
                display:none;
              }

              a {
                font-family: $font-family-muli;
                font-size: 1rem;
                display: block;
                width: 95%;

                &:after {
                  content: 'o';
                  font-size: 1em;
                  font-weight: 400;
                  top: 50%;
                  font-family: owsd;
                  position: absolute;
                  right: 0;
                  transform: translateY(-50%);
                }
              }
            }


          }
        }
      }

    }
  }

  .members-new-wrapper {
    h1 {
      flex-basis:100%;
      color: $color-base-black;
      margin-bottom: 30px;
      margin-top: 80px;
      font-family: $font-family-muli;
      font-size: 2.5rem;
      font-weight: 200;
      text-transform:uppercase;
      text-transform: uppercase;
      padding: 15px 0;
    }

    .view-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .views-row {
        padding-left: 0;
        padding-right: 0;
        margin-bottom:50px;
        width: calc(25% - 22.5px);
        float: none;

        @media (max-width: 1024px) {
          width: calc(33.333333% - 22.5px);
        }

        @media (max-width: 768px) {
          width: calc(50% - 22.5px);
        }

        @media (max-width: 400px) {
          width: 100%;
        }

        .views-field {
          flex-basis:100%;
          margin-bottom: 7px;
        }

        .views-field-field--picture {
          z-index: -1;
          .field-content {
            padding-bottom:100%;
            .mamber-bg {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border: 0;
              background-size: 100%;
              background-repeat: no-repeat;
              pointer-events: none;
            }
          }

        }


        img {
          max-width: 100%;
        }
      }
    }

    &.friends-owsd {
      background-color: lighten($color-green-light, 5%);
      margin-top: 80px;

      .view {
        margin: 0 auto;
        @media (min-width: $screen-sm-min) {
          width: $container-sm;
        }
        @media (min-width: $screen-md-min) {
          width: $container-md;
        }
        @media (min-width: $screen-lg-min) {
          width: $container-lg;
        }
      }

      .view-header {
        display:none;
      }

      .view-filters {
        display:none;
      }

      .view-content {
        justify-content: flex-start;
        width: auto !important;
        margin-left: -15px;
        margin-right: -15px;

        .views-row {
          margin-left:15px;
          margin-right:15px;
          width: calc(25% - 30px);

          @media (max-width: 1024px) {
            width: calc(33.333333% - 30px);
          }

          @media (max-width: 768px) {
            width: calc(50% - 30px);
          }

          @media (max-width: 400px) {
            width: 100%;
          }
        }
      }

      h1 {
        margin-top: 30px;
      }
    }
  }

  .awardees {
    width:33px;
    height:33px;
    background-color: #3eaf49;
    float: left;

    &>div {
      width:100%;
      height:100%;
    }

    .icon-medal {
      display: flex;
      height: 100%;
      color: white;
      font-size: 1.5rem;
      justify-content: center;
      align-items: center;

      &:before {
        color: white;
        font-size: 1.3rem;
      }
    }
  }

  .icofonts {
    &.fa-star {
      display: flex;
      width:33px;
      height:33px;
      color: white;
      font-size: 1.5rem;
      justify-content: center;
      align-items: center;
      background-color: $color-yellow;

      &:before {
        color: white;
        font-size: 1.3rem;
      }
    }
  }

  .fellows {
    width:33px;
    height:33px;
    background-color: #00add8;
    float: left;

    &>div {
      width:100%;
      height:100%;
    }

    &>span {
      width:100%;
      height:100%;
      display: block;
    }

    .icofonts {
      display: flex;
      height: 100%;
      color: white;
      font-size: 1.3rem;
      justify-content: center;
      align-items: center;

      &.icofont-laboratory {
        background-color: #f26d25;
      }

      &.icofont-test-tube-alt {
        background-color: #007dbb;
        &:before {
          //content: "\eaf2";
          content: "\ec25";
        }
      }
    }



    .icon-fellow {
      display: flex;
      height: 100%;
      color: white;
      font-size: 1.3rem;
      justify-content: center;
      align-items: center;

      &:before {
        color: white;
        font-size: 1.5rem;
      }
    }


  }

  .icofonts {
    display: flex;
    height: 33px;
    width: 33px;
    color: white;
    font-size: 1.3rem;
    justify-content: center;
    align-items: center;

    &.icofont-test-tube-alt {
      background-color: #007dbb;
      &:before {
        //content: "\eaf2";
        content: "\ec25";
      }
    }
  }

  .views-field.views-field-title {
    font-size: 1.1rem;
    font-weight: 600;
    a {
      span {
        text-transform: uppercase;
        font-weight: 800;
      }
    }

  }

  .views-field-field-member-year-of-membership {
    color: $color-grey-mid;
    .field-content {
      display:inline;
    }
  }

  .views-field.views-field-field-field-specialization {
    font-weight: 800;
    font-size: .9rem;
    margin-bottom:0;
  }

  .views-field-field-member-type {
    color: $color-grey-mid;
    margin-bottom:0 !important;
    .field-content {
      display:inline;
    }
  }

  .pager-show-more {
    a {
      border-radius: 0;
      border: 2px solid $color-green-text;
      padding: 10px 20px;
      font-weight: 600;
      font-size: 1rem;
    }

  }



  .nc-members-form {

    .view-header {
      background-color:lighten($color-green-light, 5%);
      padding: 50px 50px 0 50px;
        p {
          width: 100%;
          margin: 0 auto;
          padding-left:15px;
          padding-right:15px;
          font-size: 1.3rem;
          font-weight: 800;

          @media (min-width: $screen-sm-min) {
            width: $container-sm;
          }
          @media (min-width: $screen-md-min) {
            width: $container-md;
          }
          @media (min-width: $screen-lg-min) {
            width: $container-lg;
          }
        }
    }

    .view-content {
      margin: 0 auto;
      padding-left:15px;
      padding-right:15px;

      @media (min-width: $screen-sm-min) {
        width: $container-sm;
      }
      @media (min-width: $screen-md-min) {
        width: $container-md;
      }
      @media (min-width: $screen-lg-min) {
        width: $container-lg;
      }

      .alert  {
        border-radius: 0;

        &.alert-success {
          background-color:lighten($color-green-light, 5%);
          border-color:$color-green-light;
          color: $color-base-black;
        }
      }
    }

    .view-filters  {
      background-color:lighten($color-green-light, 5%);
      padding: 15px 50px 50px 50px;
        form {
          width: 100%;
          margin: 0 auto;
          padding-left:15px;
          padding-right:15px;
          max-width: 100%;

          @media (min-width: $screen-sm-min) {
            width: $container-sm;
          }
          @media (min-width: $screen-md-min) {
            width: $container-md;
          }
          @media (min-width: $screen-lg-min) {
            width: $container-lg;
          }

          .views-exposed-widgets {
            display:flex;
            flex-wrap:wrap;
            align-items:center;

            .form-text {
              height:46px !important;
              border:none !important;
            }

            .views-widget-filter-combine {
              flex-basis: calc(70% - 10px);
              margin-right: 20px;
              order: 1;

              @media screen and (max-width: 600px) {
                flex-basis: 100%;
                margin-right: 0;
              }

              label {
                display: block;
                font-size: 1rem;
                margin-bottom: 5px;
              }
            }

            .views-widget-filter-combine_2 {
              flex-basis: calc(30% - 10px);
              order: 2;

              @media screen and (max-width: 600px) {
                flex-basis: 100%;
              }

              label {
                display: block;
                font-size: 1rem;
                margin-bottom: 5px;
              }
            }

            .views-widget-filter-secondary {
              flex-basis: 100%;
              order: 3;
              margin-top: 20px;

              legend {
                width:180px;
                background-color: $nc-green;
                display: none;


                .fieldset-legend {
                  text-align: center;

                  .fieldset-title {
                    color: $color-base-white;
                    padding: 15px;
                    display: block;
                  }
                }

              }

              .fieldset-wrapper {
                margin-top: 15px;
                margin-bottom: 15px;
                padding: 30px 30px 5px 30px;
                background-color:$color-green-light;
                .bef-secondary-options {
                  /*display: grid;
                  grid-template-columns: auto auto auto;
                  grid-column-gap: 30px;*/
				  display: flex;
                  flex-wrap: wrap;

                  @media screen and (max-width: 959px) {
                    grid-template-columns: 100%;
                  }

                  .form-item {
                    margin-bottom: 25px;
					flex: 1 0 calc(33.333% - 30px);
                    padding-left: 15px;
                    padding-right: 15px;

                    label {
                      display: block;
                      font-size: 1rem;
                      margin-bottom: 5px;
                    }

                    .form-select {
                      width: 100%;
                      padding: 14px 6px;
                    }
                  }
                }
              }
            }

            .views-submit-button {
              flex-basis: 46px;
              order: 4;
              text-align: center;
              position:relative;

              .form-submit {
                height:46px;
                width:46px;
                margin-top: 0;
                margin-right: 0;
                border:none !important;
                background-image: url(/sites/all/themes/owsd/images/search2-w.png);
                background-position: center;
                background-repeat: no-repeat;
                background-color: $nc-green;
                background-size: 25px auto;
				        color: transparent;
              }
            }

            .views-reset-button {
              display: block;
              flex-basis: 46px;
              order: 5;
              text-align: center;
              position:relative;
              margin-left:10px;

              .form-submit {
                height:46px;
                width:46px;
                margin-top: 0;
                margin-right: 0;
                border:none !important;
                background-image: url(/sites/all/themes/owsd/images/reset-w.png);
                background-position: center;
                background-repeat: no-repeat;
                background-color: $color-yellow-mid;
                background-size: 25px auto;
				color: transparent;
              }
            }
          }




        }

    }
  }

  .replaced-title {
    position: absolute;
    color:$color-base-white;
    font-size: 2rem;
    top: 7px;
    left: 8px;
  }

}
